import React, { useState } from "react";
import Calculator from "../../components/calculators/calculator";
import {
  Section,
  Container,
  PageTitle,
  SectionTitle,
  Equation,
  Note,
  InlineEquation,
  SectionSubtitle,
  SubSection,
  Centered,
} from "../../components/calculators/calculators-layout";
import {
  useFootnotes,
  FootnoteLink,
  Footnotes,
} from "../../components/hooks/use-footnotes";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import MathMLConstants from "../../components/calculators/mathml-constants";
import { Link } from "gatsby";
import { CalculatorsContextProvider } from "../../components/calculators/calculators-context";
import { Calculators } from ".";

const notes = [
  "Baker, R. W. Membrane Technology and Applications; John Wiley & Sons, Ltd.: New York, 2012",
  "Imbrogno, A., & Schäfer, A. I. (2019). Comparative study of nanofiltration membrane characterization devices of different dimension and configuration (cross flow and dead end). Journal of Membrane Science, 585, 67-80.",
  "G. Schock, A. Miquel, Mass transfer and pressure loss in spiral wound modules, Desalination, 64 (1987) 339-352.",
];

const Foldable = ({ children, foldedComponent }) => {
  const [isFolded, setIsFolded] = useState(true);

  if (isFolded)
    return (
      <div className="section has-background-white-ter mb-6">
        {foldedComponent}
        <div className="has-text-centered mt-6">
          <button
            type="button"
            className="button is-primary"
            onClick={() => setIsFolded(false)}
          >
            Expand this section
          </button>
        </div>
      </div>
    );
  else return children;
};

const ConcentrationPolarizationCalculatorPage = () => {
  const [footnotes] = useFootnotes(notes);

  return (
    <Layout>
      <Seo title="Concentration Polarization Calculator" />
      <Section>
        <Container>
          <PageTitle>Concentration Polarization Calculator</PageTitle>
        </Container>
        <ConcentrationPolarizationModulus />
      </Section>
      <Foldable
        foldedComponent={
          <FeedSiteMassTransferCoefficientRectangularChannelCrossFlow />
        }
      >
        <Section>
          <FeedSiteMassTransferCoefficientRectangularChannelCrossFlow />
          <SubSection>
            <Foldable
              foldedComponent={
                <SectionSubtitle>1.1 For laminar flow</SectionSubtitle>
              }
            >
              <ForLaminarFlow1 />
            </Foldable>
            <Foldable
              foldedComponent={
                <SectionSubtitle>1.2 For turbulent flow</SectionSubtitle>
              }
            >
              <ForTurbulentFlow1 />
            </Foldable>
          </SubSection>
        </Section>
      </Foldable>
      <Foldable
        foldedComponent={
          <FeedSiteMassTransferCoefficientInCircularStirredCell />
        }
      >
        <Section>
          <FeedSiteMassTransferCoefficientInCircularStirredCell />
          <SubSection>
            <Foldable
              foldedComponent={
                <SectionSubtitle>2.1 Laminar flow</SectionSubtitle>
              }
            >
              <ForLaminarFlow2 />
            </Foldable>
            <Foldable
              foldedComponent={
                <SectionSubtitle>2.2 Turbulent flow</SectionSubtitle>
              }
            >
              <ForTurbulentFlow2 />
            </Foldable>
          </SubSection>
        </Section>
      </Foldable>
      <Section>
        <Container>
          <Footnotes footnotes={footnotes} />
        </Container>
      </Section>
      <hr />
      <Calculators />
    </Layout>
  );
};
export default ConcentrationPolarizationCalculatorPage;

const ConcentrationPolarizationCalculator = () => (
  <Centered>
    <Calculator
      fields={[
        {
          name: "R_obs",
          label: (
            <>
              Enter observed rejection,{" "}
              <InlineEquation math={MathMLConstants.R_obs} /> =
            </>
          ),
          unit: <>unitless</>,
        },
        {
          name: "J_W",
          label: (
            <>
              Enter water flux, <InlineEquation math={MathMLConstants.J_W} /> =
            </>
          ),
          unit: (
            <>
              L m<sup>-2</sup> h<sup>-1</sup>
            </>
          ),
        },
        {
          name: "k_f",
          label: (
            <>
              Enter feed-side mass transfer coefficient,{" "}
              <InlineEquation math={MathMLConstants.k_f} /> =
            </>
          ),
          unit: (
            <>
              L m<sup>-2</sup> h<sup>-1</sup>
            </>
          ),
        },
      ]}
      outputs={[
        {
          name: "C_mC_f",
          label: (
            <>
              Output, <InlineEquation math={MathMLConstants.C_mC_f} /> =
            </>
          ),
          unit: <>unitless</>,
          calculate: ({ R_obs, J_W, k_f }) =>
            1 - R_obs + R_obs * Math.exp(J_W / k_f),
        },
      ]}
    />
  </Centered>
);

const ConcentrationPolarizationModulus = () => (
  <>
    <Container>
      <SectionTitle>
        Concentration polarization modulus,{" "}
        <i>
          <b>
            C<sub>m</sub>/C<sub>f</sub>
          </b>
        </i>
      </SectionTitle>
      <p className="mb-3 is-italic">
        Concentration polarization (CP) is the accumulation of rejected solutes
        near the membrane surface that results in the formation of a ‘polarized
        layer.’ This phenomenon increases the osmotic pressure at the membrane
        surface, resulting in flux decline from a decreased effective driving
        force across the membrane. The effect of CP on membrane performance can
        be approximated with the use of film theory as detailed below{" "}
        <FootnoteLink footnotes={notes} start={1} end={1} />.
      </p>
      <Equation math={MathMLConstants.concentrationPolarizationModulus} />
    </Container>
    <Container>
      <ConcentrationPolarizationCalculator />
      <Note>
        <InlineEquation math={MathMLConstants.R_obs} /> is generally reported
        between 0 and 100%, but it should be a value between 0 and 1 for this
        calculation. Details on determining{" "}
        <InlineEquation math={MathMLConstants.R_obs} /> can be found{" "}
        <a
          href="/calculators/membrane-performance-calculator"
          target={"_blank"}
          rel="noreferrer"
        >
          <u>here</u>
        </a>
        .
      </Note>
      <Note>
        Details for calculating <InlineEquation math={MathMLConstants.k_f} />{" "}
        can be found below. The most widely used equation in a cross-flow system
        is equation 1.1. for laminar flow. Please note that, generally, the{" "}
        <InlineEquation math={MathMLConstants.k_f} /> value is around 100 L m
        <sup>-2</sup> h<sup>-1</sup> for a cross-flow velocity of 0.8-1.6 L min
        <sup>-1</sup>.{" "}
        <b>
          Turbulent condition is defined for Re &gt; 10<sup>4</sup> and laminar
          condition for Re &lt; 10<sup>4</sup>
        </b>
      </Note>
    </Container>
  </>
);

const FeedSiteMassTransferCoefficientRectangularChannelCrossFlow = () => (
  <>
    <Container>
      <SectionTitle>
        1. Feed-side mass transfer coefficient,{" "}
        <InlineEquation math={MathMLConstants.k_f} />, in thin rectangular
        channel in cross-flow
      </SectionTitle>
      <p className="mb-3 is-italic has-text-centered">
        With <InlineEquation math={MathMLConstants.cP1h} />,{" "}
        <InlineEquation math={MathMLConstants.cP1Re} />,{" "}
        <InlineEquation math={MathMLConstants.cP1u} />,{" "}
        <InlineEquation math={MathMLConstants.cP1Sc} />,{" "}
        <InlineEquation math={MathMLConstants.cP1v} /> and assuming{" "}
        <InlineEquation math={MathMLConstants.cP1D_h} />
      </p>
    </Container>
  </>
);

const LaminarFlow1ACalculator = () => (
  <Centered>
    <CalculatorsContextProvider>
      <Calculator
        fields={[
          {
            name: "Q",
            label: (
              <>
                Enter volumetric feed flow rate,{" "}
                <InlineEquation math={MathMLConstants.Q} /> =
              </>
            ),
            unit: (
              <>
                m<sup>3</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "D",
            label: (
              <>
                Enter solute diffusivity,{" "}
                <InlineEquation math={MathMLConstants.D} /> =
              </>
            ),
            unit: (
              <>
                m<sup>2</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "W_c",
            label: (
              <>
                Enter channel width,{" "}
                <InlineEquation math={MathMLConstants.W_c} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "H_c",
            label: (
              <>
                Enter channel height,{" "}
                <InlineEquation math={MathMLConstants.H_c} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "L_c",
            label: (
              <>
                Enter channel length,{" "}
                <InlineEquation math={MathMLConstants.L_c} /> =
              </>
            ),
            unit: <>m</>,
          },
        ]}
        outputs={[
          {
            name: "k_f_speed",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                m s<sup>-1</sup>
              </>
            ),
            calculate: ({ Q, D, W_c, H_c, L_c }) =>
              1.62 * Math.pow((Q * D * D) / (2 * W_c * H_c * H_c * L_c), 1 / 3),
          },
          {
            name: "k_f_converted",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                L m<sup>-2</sup> h<sup>-1</sup>
              </>
            ),
            calculate: ({ k_f_speed }) => k_f_speed * 3.6 * Math.pow(10, 6),
          },
        ]}
      />
    </CalculatorsContextProvider>
  </Centered>
);
const LaminarFlow1BCalculator = () => (
  <Centered>
    <CalculatorsContextProvider>
      <Calculator
        fields={[
          {
            name: "Q",
            label: (
              <>
                Enter volumetric feed flow rate,{" "}
                <InlineEquation math={MathMLConstants.Q} /> =
              </>
            ),
            unit: (
              <>
                m<sup>3</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "D",
            label: (
              <>
                Enter solute diffusivity,{" "}
                <InlineEquation math={MathMLConstants.D} /> =
              </>
            ),
            unit: (
              <>
                m<sup>2</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "W_c",
            label: (
              <>
                Enter channel width,{" "}
                <InlineEquation math={MathMLConstants.W_c} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "H_c",
            label: (
              <>
                Enter channel height,{" "}
                <InlineEquation math={MathMLConstants.H_c} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "L_c",
            label: (
              <>
                Enter channel length,{" "}
                <InlineEquation math={MathMLConstants.L_c} /> =
              </>
            ),
            unit: <>m</>,
          },
        ]}
        outputs={[
          {
            name: "k_f_speed",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                m s<sup>-1</sup>
              </>
            ),
            calculate: ({ Q, D, W_c, H_c, L_c }) =>
              1.86 * Math.pow((Q * D * D) / (2 * W_c * H_c * H_c * L_c), 1 / 3),
          },
          {
            name: "k_f_converted",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                L m<sup>-2</sup> h<sup>-1</sup>
              </>
            ),
            calculate: ({ k_f_speed }) => k_f_speed * 3.6 * Math.pow(10, 6),
          },
        ]}
      />
    </CalculatorsContextProvider>
  </Centered>
);
const LaminarFlow1CCalculator = () => (
  <Centered>
    <CalculatorsContextProvider>
      <Calculator
        fields={[
          {
            name: "Q",
            label: (
              <>
                Enter volumetric feed flow rate,{" "}
                <InlineEquation math={MathMLConstants.Q} /> =
              </>
            ),
            unit: (
              <>
                m<sup>3</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "D",
            label: (
              <>
                Enter solute diffusivity,{" "}
                <InlineEquation math={MathMLConstants.D} /> =
              </>
            ),
            unit: (
              <>
                m<sup>2</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "mu",
            label: (
              <>
                Enter dynamic solution viscosity,{" "}
                <InlineEquation math={MathMLConstants.mu} /> =
              </>
            ),
            unit: <>Pa s</>,
          },
          {
            name: "rho",
            label: (
              <>
                Enter solution density,{" "}
                <InlineEquation math={MathMLConstants.rho} /> =
              </>
            ),
            unit: (
              <>
                kg m<sup>-3</sup>
              </>
            ),
          },
          {
            name: "W_c",
            label: (
              <>
                Enter channel width,{" "}
                <InlineEquation math={MathMLConstants.W_c} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "H_c",
            label: (
              <>
                Enter channel height,{" "}
                <InlineEquation math={MathMLConstants.H_c} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "L_c",
            label: (
              <>
                Enter channel length,{" "}
                <InlineEquation math={MathMLConstants.L_c} /> =
              </>
            ),
            unit: <>m</>,
          },
        ]}
        outputs={[
          {
            name: "k_f_speed",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                m s<sup>-1</sup>
              </>
            ),
            calculate: ({ Q, D, W_c, H_c, L_c, mu, rho }) =>
              0.332 *
              Math.pow(
                (2 * Q * D * D) / (W_c * H_c * H_c * (mu / rho)),
                1 / 2
              ) *
              Math.pow((2 *  (mu / rho) * H_c) / (L_c * D), 1 / 3),
          },
          {
            name: "k_f_converted",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                L m<sup>-2</sup> h<sup>-1</sup>
              </>
            ),
            calculate: ({ k_f_speed }) => k_f_speed * 3.6 * Math.pow(10, 6),
          },
        ]}
      />
    </CalculatorsContextProvider>
  </Centered>
);
const ForLaminarFlow1 = () => (
  <>
    <Container>
      <SectionSubtitle>1.1 For laminar flow</SectionSubtitle>
      <p className="mb-3 has-text-weight-bold">
        a. When the velocity profile is fully developed at the channel entrance,
        even though the concentration profile is developing along the length of
        the channel.
      </p>
      <p className="mb-3 has-text-weight-bold">
        For 100 &lt;{" "}
        <InlineEquation
          math={
            '<math xmlns="http://www.w3.org/1998/Math/MathML"> <mi mathcolor="#000000">R</mi> <mi mathcolor="#000000">e</mi> <mi mathcolor="#000000">S</mi> <mi mathcolor="#000000">c</mi> <mfrac mathcolor="#000000"> <mrow> <msub> <mrow> <mi mathcolor="#000000">D</mi> </mrow> <mrow> <mi mathcolor="#000000">h</mi> </mrow> </msub> </mrow> <mrow> <msub> <mrow> <mi mathcolor="#000000">L</mi> </mrow> <mrow> <mi mathcolor="#000000">c</mi> </mrow> </msub> </mrow> </mfrac> </math>'
          }
        />{" "}
        &lt; 5000 :
      </p>
      <Equation math={MathMLConstants.cP11ak_f} />
      <LaminarFlow1ACalculator />
      <p className="mb-3 has-text-weight-bold">
        b. When the velocity profile is completely developed at a distance from
        the channel inlet (L*), even though the concentration profile is
        developing along the length of the channel.
      </p>
      <p className="mb-3 has-text-weight-bold">
        For L<sub>c</sub> &gt; L* :
      </p>
      <Equation math={MathMLConstants.cP11bk_f} />
      <LaminarFlow1BCalculator />
      <p className="mb-3 has-text-weight-bold">
        c. When the velocity profile is completely developed at a distance from
        the channel inlet (L*), even though the concentration profile is
        developing along the length of the channel.
      </p>
      <p className="mb-3 has-text-weight-bold">
        For L<sub>c</sub> &lt; L* :
      </p>
      <Equation math={MathMLConstants.cP11ck_f} />
      <LaminarFlow1CCalculator />
    </Container>
  </>
);

const TurbulentFlow1ACalculator = () => (
  <Centered>
    <CalculatorsContextProvider>
      <Calculator
        fields={[
          {
            name: "Q",
            label: (
              <>
                Enter volumetric feed flow rate,{" "}
                <InlineEquation math={MathMLConstants.Q} /> =
              </>
            ),
            unit: (
              <>
                m<sup>3</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "D",
            label: (
              <>
                Enter solute diffusivity,{" "}
                <InlineEquation math={MathMLConstants.D} /> =
              </>
            ),
            unit: (
              <>
                m<sup>2</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "mu",
            label: (
              <>
                Enter dynamic solution viscosity,{" "}
                <InlineEquation math={MathMLConstants.mu} /> =
              </>
            ),
            unit: <>Pa s</>,
          },
          {
            name: "rho",
            label: (
              <>
                Enter solution density,{" "}
                <InlineEquation math={MathMLConstants.rho} /> =
              </>
            ),
            unit: (
              <>
                kg m<sup>-3</sup>
              </>
            ),
          },
          {
            name: "W_c",
            label: (
              <>
                Enter channel width,{" "}
                <InlineEquation math={MathMLConstants.W_c} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "H_c",
            label: (
              <>
                Enter channel height,{" "}
                <InlineEquation math={MathMLConstants.H_c} /> =
              </>
            ),
            unit: <>m</>,
          },
        ]}
        outputs={[
          {
            name: "k_f_speed",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                m s<sup>-1</sup>
              </>
            ),
            calculate: ({ Q, D, W_c, H_c, mu, rho }) =>
              0.0115 *
              (D / H_c) *
              Math.pow((2 * Q * rho) / (mu * W_c), 0.875) *
              Math.pow(mu / (rho * D), 0.25),
          },
          {
            name: "k_f_converted",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                L m<sup>-2</sup> h<sup>-1</sup>
              </>
            ),
            calculate: ({ k_f_speed }) => k_f_speed * 3.6 * Math.pow(10, 6),
          },
        ]}
      />
    </CalculatorsContextProvider>
  </Centered>
);
const TurbulentFlow1BCalculator = () => (
  <Centered>
    <CalculatorsContextProvider>
      <Calculator
        fields={[
          {
            name: "Q",
            label: (
              <>
                Enter volumetric feed flow rate,{" "}
                <InlineEquation math={MathMLConstants.Q} /> =
              </>
            ),
            unit: (
              <>
                m<sup>3</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "D",
            label: (
              <>
                Enter solute diffusivity,{" "}
                <InlineEquation math={MathMLConstants.D} /> =
              </>
            ),
            unit: (
              <>
                m<sup>2</sup> s<sup>-1</sup>
              </>
            ),
          },
          {
            name: "mu",
            label: (
              <>
                Enter dynamic solution viscosity,{" "}
                <InlineEquation math={MathMLConstants.mu} /> =
              </>
            ),
            unit: <>Pa s</>,
          },
          {
            name: "rho",
            label: (
              <>
                Enter solution density,{" "}
                <InlineEquation math={MathMLConstants.rho} /> =
              </>
            ),
            unit: (
              <>
                kg m<sup>-3</sup>
              </>
            ),
          },
          {
            name: "W_c",
            label: (
              <>
                Enter channel width,{" "}
                <InlineEquation math={MathMLConstants.W_c} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "H_c",
            label: (
              <>
                Enter channel height,{" "}
                <InlineEquation math={MathMLConstants.H_c} /> =
              </>
            ),
            unit: <>m</>,
          },
        ]}
        outputs={[
          {
            name: "k_f_speed",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                m s<sup>-1</sup>
              </>
            ),
            calculate: ({ Q, D, W_c, H_c, mu, rho }) =>
              0.0048 *
              (D / H_c) *
              Math.pow((2 * Q * rho) / (mu * W_c), 0.91) *
              Math.pow(mu / (rho * D), 0.35),
          },
          {
            name: "k_f_converted",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                L m<sup>-2</sup> h<sup>-1</sup>
              </>
            ),
            calculate: ({ k_f_speed }) => k_f_speed * 3.6 * Math.pow(10, 6),
          },
        ]}
      />
    </CalculatorsContextProvider>
  </Centered>
);
const ForTurbulentFlow1 = () => (
  <>
    <Container>
      <SectionSubtitle>1.2 For turbulent flow</SectionSubtitle>
      <p className="mb-3 has-text-weight-bold">a. For 1 &le; Sc &le; 1000:</p>
      <Equation math={MathMLConstants.cP12ak_f} />
      <TurbulentFlow1ACalculator />
      <p className="mb-3 has-text-weight-bold">b. For Sc &ge; 1000:</p>
      <Equation math={MathMLConstants.cP12bk_f} />
      <TurbulentFlow1BCalculator />
    </Container>
  </>
);

const FeedSiteMassTransferCoefficientInCircularStirredCell = () => (
  <>
    <Container>
      <SectionTitle>
        2. Feed-side mass transfer coefficient,{" "}
        <InlineEquation math={MathMLConstants.k_f} />, in circular stirred cell
      </SectionTitle>
      <p className="mb-3 is-italic has-text-centered">
        With <InlineEquation math={MathMLConstants.cP2h} />,{" "}
        <InlineEquation math={MathMLConstants.cP2Re} />,{" "}
        <InlineEquation math={MathMLConstants.cP2Sc} />,{" "}
        <InlineEquation math={MathMLConstants.cP2v} /> and assuming{" "}
        <InlineEquation math={MathMLConstants.cP2D_h} />
      </p>
    </Container>
  </>
);

const LaminarFlow2Calculator = () => (
  <Centered>
    <CalculatorsContextProvider>
      <Calculator
        fields={[
          {
            name: "R_S",
            label: (
              <>
                Enter stirring rate,{" "}
                <InlineEquation math={MathMLConstants.R_S} /> =
              </>
            ),
            unit: <>rpm</>,
          },
          {
            name: "r",
            label: (
              <>
                Enter cell radius, <InlineEquation math={MathMLConstants.r} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "mu",
            label: (
              <>
                Enter dynamic solution viscosity,{" "}
                <InlineEquation math={MathMLConstants.mu} /> =
              </>
            ),
            unit: <>Pa s</>,
          },
          {
            name: "rho",
            label: (
              <>
                Enter solution density,{" "}
                <InlineEquation math={MathMLConstants.rho} /> =
              </>
            ),
            unit: (
              <>
                kg m<sup>-3</sup>
              </>
            ),
          },
          {
            name: "D",
            label: (
              <>
                Enter solute diffusivity,{" "}
                <InlineEquation math={MathMLConstants.D} /> =
              </>
            ),
            unit: (
              <>
                m<sup>2</sup> s<sup>-1</sup>
              </>
            ),
          },
        ]}
        outputs={[
          {
            name: "k_f_speed",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                m s<sup>-1</sup>
              </>
            ),
            calculate: ({ D, r, R_S, rho, mu }) =>
              ((0.1425 * D) / r) *
              Math.pow((2 * Math.PI * R_S * r * r * rho) / (60 * mu), 0.567) *
              Math.pow(mu / (rho * D), 0.33),
          },
          {
            name: "k_f_converted",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                L m<sup>-2</sup> h<sup>-1</sup>
              </>
            ),
            calculate: ({ k_f_speed }) => k_f_speed * 3.6 * Math.pow(10, 6),
          },
        ]}
      />
    </CalculatorsContextProvider>
  </Centered>
);

const ForLaminarFlow2 = () => (
  <>
    <Container>
      <SectionSubtitle>2.1 Laminar flow</SectionSubtitle>
      <p className="mb-3 has-text-weight-bold">
        For Re &lt; 3&times;10<sup>4</sup> :
      </p>
      <Equation math={MathMLConstants.cP21k_f} />
      <LaminarFlow2Calculator />
    </Container>
  </>
);

const TurbulentFlow2Calculator = () => (
  <Centered>
    <CalculatorsContextProvider>
      <Calculator
        fields={[
          {
            name: "R_S",
            label: (
              <>
                Enter stirring rate,{" "}
                <InlineEquation math={MathMLConstants.R_S} /> =
              </>
            ),
            unit: <>rpm</>,
          },
          {
            name: "r",
            label: (
              <>
                Enter cell radius, <InlineEquation math={MathMLConstants.r} /> =
              </>
            ),
            unit: <>m</>,
          },
          {
            name: "mu",
            label: (
              <>
                Enter dynamic solution viscosity,{" "}
                <InlineEquation math={MathMLConstants.mu} /> =
              </>
            ),
            unit: <>Pa s</>,
          },
          {
            name: "rho",
            label: (
              <>
                Enter solution density,{" "}
                <InlineEquation math={MathMLConstants.rho} /> =
              </>
            ),
            unit: (
              <>
                kg m<sup>-3</sup>
              </>
            ),
          },
          {
            name: "D",
            label: (
              <>
                Enter solute diffusivity,{" "}
                <InlineEquation math={MathMLConstants.D} /> =
              </>
            ),
            unit: (
              <>
                m<sup>2</sup> s<sup>-1</sup>
              </>
            ),
          },
        ]}
        outputs={[
          {
            name: "k_f_speed",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                m s<sup>-1</sup>
              </>
            ),
            calculate: ({ D, r, R_S, rho, mu }) =>
              ((0.022 * D) / r) *
              Math.pow((2 * Math.PI * R_S * r * r * rho) / (60 * mu), 0.75) *
              Math.pow(mu / (rho * D), 0.33),
          },
          {
            name: "k_f_converted",
            label: (
              <>
                Output, <InlineEquation math={MathMLConstants.k_f} /> =
              </>
            ),
            unit: (
              <>
                L m<sup>-2</sup> h<sup>-1</sup>
              </>
            ),
            calculate: ({ k_f_speed }) => k_f_speed * 3.6 * Math.pow(10, 6),
          },
        ]}
      />
    </CalculatorsContextProvider>
  </Centered>
);

const ForTurbulentFlow2 = () => (
  <>
    <Container>
      <SectionSubtitle>2.2 Turbulent flow</SectionSubtitle>
      <p className="mb-3 has-text-weight-bold">
        For 32&times;10<sup>3</sup> &lt; Re &lt; 82&times;10<sup>3</sup> :
      </p>
      <Equation math={MathMLConstants.cP22k_f} />
      <TurbulentFlow2Calculator />
    </Container>
  </>
);
